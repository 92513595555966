@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/*@import "~antd/dist/antd.css";*/

:root {
  --text-color: #da1c4b;
  --twitter: #1da1f2;
  --linkedin: #0077b5;
  --github: #333333;
  --website: #000;
  --gmail: #db4437;
  --white: #ffffff;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Layout CSS */
.ant-layout {
  background: url("/public/HD-neuesfoto-scharf.jpg");
  background-size: cover;
  /* Passen Sie die Hintergrundgröße an */
  background-attachment: fixed;
  /* Feste Position */
  min-height: 100vh;
  /* Mindesthöhe des Containers, um das Bild anzuzeigen */
  display: flex;
}

.backgrou1 {
  background: url("./assets/whitebg.jpeg");
  background-size: cover;
  /* Passen Sie die Hintergrundgröße an */
  background-attachment: fixed;
  /* Feste Position */
  min-height: 100vh;
  /* Mindesthöhe des Containers, um das Bild anzuzeigen */
  display: flex;
}

.backgrou2 {
  background: url("./assets/blackbg.jpeg");
  background-size: cover;
  /* Passen Sie die Hintergrundgröße an */
  background-attachment: fixed;
  /* Feste Position */
  min-height: 100vh;
  /* Mindesthöhe des Containers, um das Bild anzuzeigen */
  display: flex;
}







.act {
  background: linear-gradient(90deg,
      rgba(255, 46, 59, 0.28) 0%,
      rgba(255, 46, 59, 0.11) 100%);
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #ff2e3b !important;
  border-color: #ff2e3b !important;
  color: white;
  border-radius: 50%;
}

.ant-pagination-item-link {
  border-color: #ff2e3b !important;
  border-radius: 100% !important;
}

.ant-pagination-item-link span svg {
  fill: #ff2e3b;
}

.ant-pagination-item-active a {
  color: white !important;
}

.ant-pagination-item {
  background: white;
  border-color: #ff2e3b;
  border-radius: 50%;
}

.ant-table-pagination-right {
  justify-content: center;
}

.ant-pagination-item a {
  color: #ff2e3b;
}

.ant-pagination-disabled .ant-pagination-item-link {
  border-color: #3f2b2d !important;
}

.ant-pagination-disabled span svg {
  fill: #3f2b2d !important;
}

.ant-table-thead>tr>th {
  border: none !important;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #71717a;
  background: none;
}

.ant-table-tbody>tr>td {
  border-bottom: 1px solid #242424 !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #71717a;
  line-height: 21px;
}

.ant-table-tbody>tr>td {
  padding: 9px 14px !important;
}

.ant-layout-header,
.ant-layout-footer {
  text-align: center;
  background: var(--text-color);
  color: var(--white);
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 64px;
}

button.ant-switch {
  background-color: rgba(0, 0, 0, 0.25);
}

.ant-layout-header .ant-btn-link {
  color: #fff;
}

button.ant-btn-primary {
  background-color: #191919;
  border-color: #fff;
}

button.ant-btn-primary:hover,
button.ant-btn-primary:focus {
  background-color: #191919;
  border-color: #fff;
}

.ant-layout-content {
  padding-top: 0px;
}
.userEdit label {
  width: 160px;
}
.userEdit input, .userEdit .ant-input-affix-wrapper.ant-input-password {
  width: 450px;
}
.userEdit .ant-form-item-control-input-content {
  width: 450px;
  flex: none;
}
.userEdit button {
  margin-right: 20px;
}
.appContainer {
  /* background: #ffe9ea; */
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
  min-height: 100%;
}

.ant-tabs-tab,
.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 16px 0 16px;
}

/* Header CSS */
.header_space {
  width: 100%;
  justify-content: space-between;
  align-items: inherit;
}

.header_space_brand {
  padding: 0px;
  color: var(--white);
}

.header_space_brand:hover {
  color: var(--white);
}

.header_link {
  color: white;
  margin-left: 20px;
  margin-right: 20px;
}

.button-content {
  display: flex !important;
  align-items: center;
  /* Vertikal zentrieren */
  gap: 8px;
  /* Abstand zwischen Bild und Text anpassen */
  font-size: 36px;
  padding-top: 15px;
}

.ant-select-item-option-content {
  white-space: pre-line;
}

/* Profile Page CSS */
.profile_page_card {
  box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%),
    0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%);
  border-radius: 4px;
}

.profile_save_btn {
  display: block;
  margin-left: auto;
}

.login_submit_btn {
  display: block;
  width: 100%;
}

.alert_error {
  margin-bottom: 8px;
}

.form_help_text {
  text-align: center;
}

.anmeldescreen {
  margin: 120px 0px;
}

/** Anchor Menu **/
/* Add this CSS to your stylesheet */
.sidebar {
  position: absolute;
  /* Position muss absolut sein */
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  /* Die Höhe wird auf 100% des übergeordneten Elements festgelegt */
  /* background-color: #f0f0f0; */
  overflow-y: auto;
  padding: 20px 16px 16px 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  /* border-radius: 10px 0px 0px 10px; */
}

.sidebar .ant-anchor-wrapper {
  position: fixed;
}

.FormContainer {
  padding: 20px 0px 60px 0px;
  min-height: 500px;
}

.roundedright {
  border-radius: 30px 0 0 30px !important;
}

.ant-select-selector {
  border: none !important;
}


.ant-card-head {
  border-bottom: none !important;
}

/* Style the links inside the sidebar */
.ant-anchor-link {
  display: block;
  margin: 0px 0;
}

/* Optional: Style the active link */
.ant-anchor-link-title {
  white-space: normal;
  width: 180px;
}


.ant-anchor-link-active {
  background: linear-gradient(90deg, rgba(255, 46, 59, 0.28) 0%, rgba(255, 46, 59, 0.11) 100%);
  border-left: 4px solid #FF2E3B;
}


.ant-anchor-link-active>a {
  color: #FF2E3B !important;
  /* Change the color to indicate active link */
  font-weight: bold;
  /* Optional: Make the active link bold */
}

/* Cards Bewertungsobjekt */
.cardsBewertungsobjekt .ant-card,
.cardsGutachten .ant-card {
  border-radius: 10px;
  background: #ff2e3b;
  margin: 5px;
  text-align: center;
  border: none;
  border-radius: 40px;
  border-bottom: 1px solid #ff2e3b;
  color: white;
}

.cardsBewertungsobjekt .ant-card-body {
  padding: 14px!important;
}

.whiteNew {
  color: white;
}



.cardsGutachten .ant-card:hover {
  background: black;

}


.cardsGutachten1 .ant-card {
  border-radius: 10px;
  background: #ff2e3b;
  margin: 5px;
  text-align: center;
  border: none;
  border-radius: 40px;
  border-bottom: 1px solid #ff2e3b;
  color: white;
}


.cardsGutachten1 .ant-card:hover {
  border-radius: 10px;
  background: white;
  margin: 5px;
  text-align: center;
  border: none;
  border-radius: 40px;
  border-bottom: 1px solid #ff2e3b;
  color: white;
}


.cardsGutachten1 .ant-card:hover .whiteNew {
  color: black;
}
.ant-card-body .ant-form-item-label > label {
  max-width: 200px;
  white-space: normal;
  height:auto;
}
.ant-form-item-label > label::after {
  content: '';
}

.cardsBewertungsobjekt .ant-card-bordered,
.cardsGutachten .ant-card-bordered {
  border: 1px solid #595959;
}

.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  color: black;
  font-size: 16px;
}

.docx-wrapper {
  background: white !important;
}

.docx span {
  font-family: Arial, Helvetica, sans-serif !important;
}

/*.with-icon {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  color: #ccc;
}*/

.save-icon {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  color: #ccc;
  transition: opacity 0.1s ease;
  /* Add transition for opacity effect */
}

.SaveIcon {
  font-size: 32px;
  /* background: var(--text-color); */
  background: white;
  width: 100px;
  height: 60px;
  color: white;
  text-align: center;
  border-radius: 5px;
  position: fixed;
  bottom: 80px;
  left: 50%;
  margin-left: -30px;
  padding-top: 5px;
  z-index: 9999;
  animation: pulse 1s infinite;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  bottom: 80px !important;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
  height: fit-content !important;
  top: unset !important;
}



@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.signTop {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: auto;
}

.inputsAll {
  border: 1px solid #9B9B9B !important;
  color: #000000 !important;
  width: 258px !important;
  height: 36px !important;
  border-radius: 7px;
  outline: none !important;
  font-size: 16px;
  /* padding: 1px !important; */
}


.selectInput {
  border: 1px solid #9B9B9B !important;
  color: #000000 !important;
  width: 258px !important;
  height: 36px !important;
  border-radius: 7px;
  outline: none !important;
  font-size: 16px;
  padding: 1px !important;
}


.selectInput:focus {
  box-shadow: none !important;
}

.selectInput>.ant-input-number-handler-wrap {
  border-radius: 7px;
}









.inputsAll:focus {
  box-shadow: none !important;
}

.inputsAll>.ant-input-number-handler-wrap {
  border-radius: 7px;
}








.ant-col-8 {
  flex: unset !important;
  min-width: fit-content !important;

}


.textAreBes {
  width: 300px !important;
  min-height: 63px !important;
}

.textAreBes1 {
  width: 260px !important;
  min-height: 63px !important;
  border: 1px solid #9B9B9B !important;
}


.inputsAll1 {
  border: 1px solid #9B9B9B !important;
  color: #9B9B9B !important;
  width: 258px !important;
  height: 36px !important;
  border-radius: 5px;
}


.inputsAll4 {
  color: #9B9B9B !important;
  max-width: 300px !important;
  border-radius: 5px;
}

.ant-upload.ant-upload-drag {
  padding: 15px;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: red !important;
}

/* .ant-btn:hover {
  color: red !important;
  border-color: red !important;
} */

.ant-anchor-link-title:hover {
  color: red !important;
}

/* 
.ant-form-item {
  width: 100% !important;
} */

.newClass {
  opacity: 1;
}

.newClass>.ant-row {
  width: 500px !important;
}


.newClass1 {
  opacity: 1;
}

.newClass1>.ant-row {
  width: 500px !important;
}






.whiteText:hover {
  color: white !important;
}


.ant-input:focus {
  border-color: red;
}

.lastButtons {
  border: 1px solid #5F5F5F;
  border-radius: 20px;
  padding: 10px 15px;
  color: #FFFFFF;
  gap: 10px;
  background: #FF2E3B;
}

.lastButtons:hover {
  border: 1px solid #5F5F5F;
  color: #FFFFFF;
  background: black;
}



.lastButtons1 {
  border: 1px solid #5F5F5F;
  border-radius: 20px;
  padding: 10px 15px;
  color: white;
  gap: 10px;
  background: #FF2E3B;
}

.lastButtons1:hover {
  border: 1px solid #5F5F5F;
  border-radius: 20px;
  padding: 10px 15px;
  color: black;
  gap: 10px;
  background: #FFFFFF;
}



#containerreader {
  max-width: 100%;
  overflow-x: auto;
}


.borderRadiuClass {
  border-radius: 0 15px 15px 0 !important;
}

.borderRadiuClass1>.MuiStepper-root>.MuiStep-root>.MuiStepLabel-root>.MuiStepLabel-labelContainer>.Mui-disabled {
  color: white !important;
}

.borderRadiuClass1>.MuiStepper-root>.MuiStep-root>.MuiStepLabel-root>.MuiStepLabel-iconContainer>.MuiSvgIcon-root {
  color: white !important;
  /* background: white; */
}

.borderRadiuClass1>.MuiStepper-root>.MuiStep-root>.MuiStepLabel-root>.MuiStepLabel-iconContainer>.Mui-completed {
  color: red !important;

}


.borderRadiuClass1>.MuiStepper-root>.MuiStep-root>.MuiStepLabel-root>.MuiStepLabel-iconContainer>.Mui-active {
  color: red !important;

}



.MuiStepIcon-text {
  display: none !important;
}

.MuiSvgIcon-root {
  width: 10px !important;
  height: 10px !important;
}

.MuiStepLabel-label {
  margin-top: 6px !important;
}

.MuiStepConnector-line,
.MuiStepConnector-lineHorizontal {
  margin-top: -8px;
}


.Mui-active {
  color: #FF2E3B !important;

}

.Mui-completed {
  color: #FF2E3B !important;
}


.MuiStepConnector-alternativeLabel {
  left: calc(-56% + 20px) !important;
  right: calc(44% + 20px) !important;
}


.MuiStepConnector-lineHorizontal {
  color: #FF2E3B !important;
  border-top-width: 2px !important;
}

.MuiStepLabel-alternativeLabel {
  font-size: 16px !important;
}

.Mui-completed>.MuiStepConnector-lineHorizontal {
  border-color: #FF2E3B !important;
}

.Mui-active>.MuiStepConnector-lineHorizontal {
  border-color: #FF2E3B !important;
}


.generalText {
  color: #3E3E3E;
}



.max-content {
  max-width: fit-content !important;
}

.max-content1 {
  max-width: fit-content !important;
}

.max-content1>.ant-row {
  width: 540px;
}

.max-content>.ant-row {
  width: 660px;
}


.max-content1>.ant-row>.ant-form-item-label {
  width: 280px !important;
  text-align: start;
}


.maxColor>.ant-row>.ant-form-item-label label {
  color: white !important;
}

.maxColor>.ant-form-item>.ant-row>.ant-form-item-label label {
  color: white !important;
}


.max-content1>.ant-row>.ant-form-item-control {
  align-items: flex-end;
}

.max-content>.ant-row>.ant-form-item-label {
  width: 200px !important;
  text-align: start;
}

.max-content>.ant-row>.ant-form-item-label label {
  font-size: 18px !important;
  color: #3E3E3E;
  font-weight: 500px;

}


.sacherCenter {
  width: 510px;
  margin-left: auto;
  margin-right: auto;
}



.sacherCenter>.ant-form-item>.ant-row>.ant-form-item-label {
  width: 200px;
  text-align: start;
}

.bgnone {
  background: none !important;
}


.bgwhite {
  background: white !important;
}

.bgdark {
  background: black !important;
}

.textwhite {
  color: white !important;
}

.textdark {
  color: black !important;
}

.textwhite>.ant-anchor-link-title {
  color: white !important;
}

.textdark>.ant-anchor-link-title {
  color: black !important;
}


.tableComtainer {
  opacity: 1;

}

.tableauftrag {
  padding: 25px 40px;
}


.tableComtainer>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container {
  background: black;
}

.tableComtainer>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container table thead tr th {
  background: black;
}

.tableComtainer>.ant-spin-nested-loading>.ant-spin-container>.ant-table>.ant-table-container table tbody tr:hover td {
  background: black !important;
}

.formprofile {
  opacity: 1;
}

.formprofile>.ant-row>.ant-form-item-label label {
  color: black;
  font-size: 16px !important;
}

.formprofile1>.ant-row>.ant-form-item-label label {
  color: white !important;
  font-size: 16px !important;
}

.inputProfile {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  outline: none !important;
}


.passwordInput {
  width: 400px;
  height: 40px;
  border-radius: 6px;
  margin: 10px auto 0;
  border: 1px solid #d9d9d9;
  outline: none;
  padding-left: 12px;
}


/* .customCard>.ant-card-body {
  padding: 11px 24px !important;
} */

.selectedItem {
  border-left: 4px solid #FF2E3B;
}

.ant-anchor-wrapper {
  min-width: 250px;
  margin-left: 0;
  padding-left: 0;
}

.ant-anchor-fixed {
  padding-left: 0;
  padding-top: 15px;
}

.ant-anchor-link {
  padding: 4px 0 4px 16px;
}

[data-testid="view-mode__dual-cover-menu"],
[data-testid="scroll-mode__page-menu"],
[data-testid="scroll-mode__vertical-menu"],
[data-testid="scroll-mode__horizontal-menu"],
[data-testid="scroll-mode__wrapped-menu"],
[data-testid="rotate__forward-menu"],
[data-testid="rotate__backward-menu"],
[data-testid="properties__menu"],
.rpv-core__menu-divider{
  display: none!important;
}

.drawerOuter>.ant-drawer-content-wrapper>.ant-drawer-content>.ant-drawer-wrapper-body>.ant-drawer-body {
  background-color: black;
  /* color: white !important; */
}

.lowerHeight {
  height: 100%;
}


.lowerHeight1 {
  height: 100%;
}

.lower-20 {
  bottom: 40px !important;
}


.lower-30 {
  bottom: 60px !important;
}



.anmeldung {
  opacity: 1;
}

.anmeldung>.ant-card-head>.ant-card-head-wrapper>.ant-card-head-title {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  line-height: 42px;
  color: #FF2E3B !important;
}

.audtragStuBack {
  padding: 7px 20px;
  border-radius: 13px;
  max-width: fit-content !important;
  color: #28A5FF;
}
/* BackToTopButton */
.back-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ff2e3b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.back-to-top-button:hover {
  background-color: #000000;
}
/* .tableComtainerBlack>.ant-spin-nested-loading>.ant-spin-container>.ant-pagination>.ant-pagination-prev>.ant-pagination-item-link {
  border-color: black !important;

}

.tableComtainerBlack>.ant-spin-nested-loading>.ant-spin-container>.ant-pagination>.ant-pagination-prev>.ant-pagination-item-link>.anticon-left svg {
  fill: black !important;
}


.tableComtainerBlack>.ant-spin-nested-loading>.ant-spin-container>.ant-pagination>.ant-pagination-item {
  border-color: black !important;
  background: black !important;
}

.tableComtainerBlack>.ant-spin-nested-loading>.ant-spin-container>.ant-pagination>.ant-pagination-item a {
  color: white !important;
}

.tableComtainer>.ant-spin-nested-loading>.ant-spin-container>.ant-pagination>.ant-pagination-prev>.ant-pagination-item-link>.anticon-left svg {
  fill: black !important;
}



.tableComtainer>.ant-spin-nested-loading>.ant-spin-container>.ant-pagination>.ant-pagination-item a {
  color: black !important;
}

.tableComtainer>.ant-spin-nested-loading>.ant-spin-container>.ant-pagination>.ant-pagination-item {
  border-color: black !important;
  background: white !important;
}



.tableComtainerBlack>.ant-spin-nested-loading>.ant-spin-container>.ant-pagination>.ant-pagination-next>.ant-pagination-item-link {
  border-color: black !important;

}

.tableComtainerBlack>.ant-spin-nested-loading>.ant-spin-container>.ant-pagination>.ant-pagination-next>.ant-pagination-item-link>.anticon-right svg {
  fill: black !important;
}


.tableComtainer>.ant-spin-nested-loading>.ant-spin-container>.ant-pagination>.ant-pagination-next>.ant-pagination-item-link>.anticon-right svg {
  fill: black !important;
} */

.whiteText {
  color: white !important;
}

.blackText {
  color: black !important;
}


.whitebg {
  background: white !important;
}

.whitebg:hover {
  background: white !important;
  color: black !important;
}


.blackbg {
  background: black !important;
}

.blackbg:hover {
  background: black !important;
  color: white;
}

.ant-card-bordered {
  border: none !important;
  border-bottom: 1px solid rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.ant-card-body {
  padding-top:24px!important;
}

.Link_FormItem label {
  width: 120px;
}

@media (max-width:1024px) {

  .ant-card-body {
    padding: 16px !important;
    box-shadow: none !important;
  }

}

@media (max-width:768px) {

  .ant-row {
    flex-direction: column !important;
  }

  .ant-form-item-label {
    text-align: start !important;
  }

  .ant-col-16,
  .ant-col-8 {
    max-width: 100% !important;
  }



  .newClass1>.ant-row {
    width: 250px !important;
  }


  .sacherCenter {
    width: unset;
  }


  .max-content>.ant-row {
    width: unset;
  }


  .max-content1>.ant-row {
    width: unset;
  }


  .anmeldescreen {
    margin: 20px 0;
  }

  .signTop {
    align-items: flex-start;
    height: fit-content;
    min-height: fit-content;
  }

  .tableauftrag {
    padding: 20px;
  }



}

@media (max-width:640px) {

  .passwordInput {
    width: 300px;
  }


}