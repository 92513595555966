.kONtAK {
    opacity: 1;
}

.kONtAK>.inputsSection>.ant-form-item>.ant-row>.ant-form-item-label label {
    width: 200px !important;
    text-align: start !important;
    font-size: 18px !important;
    font-weight: 500;
}

.kONtAK>.ant-form-item>.ant-row>.ant-form-item-control>.ant-form-item-control-input>.ant-select>.ant-select-selector {
    background: none !important;
}

.kONtAK>.ant-form-item>.ant-row>.ant-form-item-control>.ant-form-item-control-input>.ant-select>.ant-select-selector>.ant-select-selection-search>.ant-select-selection-search-input {
    background: none !important;
    font-size: 16px !important;
    color: black !important;
}

.txtSection {
    width: 600px;
}

.txtSection>.ant-form-item>.ant-row>.ant-form-item-label label {
    width: 200px;
    text-align: start;
    font-size: 18px !important;
    font-weight: 500;
}

.textBorder {
    border: 1px solid #9B9B9B !important;
    border-radius: 6px;
    min-height: 100px !important;
    min-width: 258px;
}

.buttonHover:hover {
    background: #FF2E3B;
    color: white !important;
}



.sacherCenter1 {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.sacherCenter7 {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}





.sacherCenter1>.ant-form-item>.ant-row>.ant-form-item-label {
    width: 235px !important;
    text-align: start;
}


.sacherCenter7>.ant-form-item>.ant-row>.ant-form-item-label {
    width: 235px !important;
    text-align: start;
    max-width: unset;
}

.sacherCenter7>.ant-form-item>.ant-row>.ant-form-item-control {
    width: 235px !important;
    text-align: start;
    max-width: unset;
}



.sacherCenter1>.ant-form-item>.ant-row>.ant-form-item-label {
    width: 235px !important;
    text-align: start;
}




.sacherCenter1>.ant-form-item>.ant-row>.ant-form-item-control {
    align-items: flex-end;
}


.sacherCenter7>.ant-form-item>.ant-row>.ant-form-item-control {
    align-items: flex-end;
}


.sacherCenterText {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.sacherCenterTextnew {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
}

.sacherCenterTextnew1 {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 28px;
}





.sacherCenterText>.ant-form-item>.ant-row>.ant-form-item-label {
    width: 235px !important;
    text-align: start;
}

.sacherCenterTextnew>.ant-form-item>.ant-row>.ant-form-item-label {
    width: 232px !important;
    text-align: start;
    max-width: unset;
}

.sacherCenterTextnew>.ant-form-item>.ant-row>.ant-form-item-control {
    max-width: unset;
    padding-left: 5px;
}

.sacherCenterTextnew1>.ant-form-item>.ant-row>.ant-form-item-label {
    width: 230px !important;
    text-align: start;
    max-width: unset;
}

.sacherCenterTextnew1>.ant-form-item>.ant-row>.ant-form-item-control {
    max-width: unset;
    padding-left: 5px;
}





.sacherCenterText>.ant-form-item>.ant-row>.ant-form-item-label {
    width: 235px !important;
    text-align: start;
    margin-right: 7px;
    padding-left: 28px;
}

.sacherCenterText>.ant-form-item>.ant-row>.ant-form-item-control {
    align-items: flex-end;
    margin-left: 20px;
}

.sacherCenter2 {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}


.sacherCenter2>.ant-form-item>.ant-row>.ant-form-item-label {
    width: 235px !important;
    text-align: start;
    padding-left: 45px;
}

.sacherCenter2>.ant-form-item>.ant-row>.ant-form-item-label {
    width: 235px !important;
    text-align: start;
}

.sacherCenter2>.ant-form-item>.ant-row>.ant-form-item-control {
    align-items: flex-end;
}

.sacherCenter2>.ant-form-item>.ant-row>.ant-form-item-control>.ant-form-item-control-input {
    margin-left: 45px;
}


.max-content2 {
    max-width: fit-content !important;
}

.max-content3 {
    display: flex;
    justify-content: center;
}


.max-content2>.ant-row {
    max-width: 750px;
}


.max-content2>.ant-row>.ant-form-item-label {
    width: 280px !important;
    text-align: start;
}

.max-content2>.ant-row>.ant-form-item-control {
    align-items: flex-end;
}


.max-content3>.ant-row {
    max-width: 593px;
}


.max-content3>.ant-row>.ant-form-item-label {
    width: 280px !important;
    text-align: start;
}

.max-content3>.ant-row>.ant-form-item-control {
    align-items: flex-end;
}



.allgemeineClass {
    opacity: 1;
}

.allgemeineClass>.ant-form-item>.ant-row>.ant-form-item-label label {
    width: 359px !important;
    text-align: start;
    font-size: 18px !important;
    font-weight: 500;
    color: #3f3f3f;
}


.allgemeineClass1 {
    opacity: 1;
}

.allgemeineClass1>.ant-form-item>.ant-row>.ant-form-item-label label {
    width: 148px !important;
    text-align: start;
    font-size: 12px !important;
    font-weight: 500;
    color: #3f3f3f;
}



.allgenTextArea {
    width: 71%;
    display: flex;

}

.allgenTextArea1 {
    width: 71%;
    display: flex;

}

.fileIcon {
    width: 60px;
    height: 60px;
}




.sacherCenterText1 {
    width: 675px;
    margin-left: auto;
    margin-right: auto;
}

.sacherCenterText1>.ant-form-item {
    width: 100%;
}

.sacherCenterText1>.ant-form-item>.ant-row>.ant-col-8 {
    flex: unset;


}

.sacherCenterText1>.ant-form-item>.ant-row>.ant-form-item-label {
    width: 235px !important;
    text-align: start;
    margin-right: 7px;

    padding-left: 38px;
}

.sacherCenterText1>.ant-form-item>.ant-row>.ant-form-item-control {
    align-items: flex-end;
    margin-left: auto;
    max-width: fit-content;
}



.anlagen {
    width: 510px;
    /* margin-left: auto;
    margin-right: auto; */
}



.anlagen>.ant-form-item>.ant-row>.ant-form-item-label {
    width: 200px;
    text-align: start;
}

.anlagen>.ant-form-item>.ant-row>.ant-form-item-control {
    width: 200px;
    align-items: flex-end;
}



.max-content4 {
    max-width: fit-content !important;
}



.max-content4>.ant-row {
    max-width: 750px;
}


.max-content4>.ant-row>.ant-form-item-label {
    width: 145px !important;
    text-align: start;
}

.max-content4>.ant-row>.ant-form-item-control {
    align-items: flex-end;
}


.fileReader1>.docx-wrapper {
    background: black !important;
}


.linksclassActive {
    background: linear-gradient(90deg, rgba(255, 46, 59, 0.28) 0%, rgba(255, 46, 59, 0.11) 100%);
    border-left: 2px solid #FF2E3B;
    min-height: 40px;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    display: flex;
    align-items: center;
    padding-left: 25px;
}

.linksclassUnactive {
    /* background: linear-gradient(90deg, rgba(255, 46, 59, 0.28) 0%, rgba(255, 46, 59, 0.11) 100%);
    border-left: 2px solid #FF2E3B; */
    min-height: 40px;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    display: flex;
    align-items: center;
    padding-left: 25px;
}


.toolparent {
    position: relative;
}


.toolchild {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 12px;
    border-radius: 6px;
    top: 58px;
    right: 58px;
    width: 160px;
    z-index: 1111111111;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.myprof {
    color: black !important;
    text-decoration: none;
    cursor: pointer;
}

.switchparent {
    position: relative;
    align-items: center;
    height: 58px;
}

.sachCenter {
    display: flex;

}

.sachCenterMain {
    width: 30%;
}


.sachCenterInner {
    width: 70%;
    padding-top: 10px;
}


.lgrelative {
    position: relative;
    height: 100% !important;
}


.textOuter>.ant-form-item {
    width: 70% !important;
}

.textOuter>.ant-form-item>.ant-row>.ant-form-item-label {
    text-align: start;
    width: 359px;
}

.textOuter>.ant-form-item>.ant-row>.ant-form-item-label label {
    text-align: start;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #3f3f3f;
}




.textInput {
    border: 1px solid #9B9B9B !important;
    color: #9B9B9B !important;
    width: 100% !important;
    border-radius: 5px;
    min-height: 63px !important;
    font-size: 14px !important;
    color: black !important;
}
.AutoCompleteTextArea.ant-select.ant-select-in-form-item {
    border: none !important;
    width: 500px !important;
}
.AutoCompleteTextArea.AutoCompleteTextAreaSmall.ant-select.ant-select-in-form-item {
    width: 258px !important;
}
.AutoCompleteTextArea.ant-select.ant-select-in-form-item .textInput {
    font-size: 16px !important;
    min-height: 35px !important;
}




.inputsAll3 {
    border: 1px solid #9B9B9B !important;
    color: #9B9B9B !important;
    width: 258px !important;
    border-radius: 5px;
}

.gutachtCard {
    margin: 20px auto !important;
}

.gutachtCard>.ant-card-body {
    padding: 12px 24px;
}

.GUtachenCardOuter {
    width: fit-content !important;
}


.menuBtn {
    width: 80px;
    background-color: #FF2E3B;
    color: white;
    border: none;
    height: 40px;
    border-radius: 25px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.auftragBtn {
    background: #FF2E3B !important;
    color: white !important;
}

.auftragBtn:hover {
    background: black !important;
}

.auftragBtn1 {
    background: #FF2E3B !important;
    color: white !important;
}

.auftragBtn1:hover {
    background: white !important;
    color: black !important;
}


.ant-select.ant-select-in-form-item {
    border: 1px solid #9B9B9B !important;
    color: #000000 !important;
    width: 258px !important;
    height: 36px !important;
    border-radius: 7px;
    outline: none !important;
    font-size: 16px;
    padding: 1px !important;
}
.ant-switch-checked {
    background-color: #008000!important;
}


@media (max-width:1536px) {

    .allgenTextArea1 {
        width: 83%;
        display: flex;

    }



    .allgenTextArea {
        width: 84%;
        display: flex;

    }

    .textInput {
        width: 500px !important;
    }



    .textOuter>.ant-form-item {
        width: 78% !important;
    }

    .textOuter>.ant-form-item>.ant-row>.ant-form-item-label {
        max-width: unset !important;
    }

    .textOuter>.ant-form-item>.ant-row>.ant-form-item-control {
        max-width: unset !important;
        width: 500px;
    }




}





@media (max-width:1440px) {

    .textOuter>.ant-form-item {
        width: 84% !important;
    }

}

@media (max-width:1200px) {

    .sachCenter {
        display: flex;
        flex-direction: column-reverse;
    }

    .sachCenterMain {
        width: 100%;
    }


    .sachCenterInner {
        width: 100%;
    }

}


@media (max-width:1280px) {

    .textOuter>.ant-form-item {
        max-width: fit-content !important;
    }


}




@media (max-width:1135px) {
    .lgrelative {
        position: absolute;
    }
}


@media (max-width:840px) {

    .switchild {
        margin-left: 0 !important;
        margin-top: 7px;

    }

    .switchparent {
        align-items: flex-start;
        padding-top: 10px;
        height: 80px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start !important;
    }


}


@media (max-width:768px) {

    .allgenTextArea {
        width: 100%;
        flex-direction: column;
        max-width: fit-content;

    }

    .allgenTextArea1 {
        width: 100%;
        flex-direction: column;
        max-width: fit-content;

    }

    .allgemeineClass>.ant-form-item>.ant-row>.ant-form-item-label label {
        font-size: 14px !important;
    }


    .sacherCenterText1>.ant-form-item>.ant-row>.ant-form-item-label {
        padding-left: 0;
    }

    .sacherCenterTextnew {
        padding-left: 35px;
        width: unset;
    }

    .sacherCenterTextnew1 {
        padding-left: 35px;
        width: unset;
    }

    /* .max-content3>.ant-row {
        flex-direction: unset;
    } */


    .sacherCenter1>.ant-form-item>.ant-row>.ant-form-item-control {
        align-items: flex-start;
    }

    .sacherCenter7>.ant-form-item>.ant-row>.ant-form-item-control {
        align-items: flex-start;
    }


    .sacherCenterText1>.ant-form-item>.ant-row>.ant-form-item-control {
        align-items: flex-start;
        max-width: fit-content !important;
        margin-right: auto;
        margin-left: 0;
    }

    .sacherCenterText1>.ant-form-item {
        max-width: fit-content;
    }

    .sacherCenterText1>.ant-form-item>.ant-row {
        max-width: fit-content;
    }

    .max-content4>.ant-row>.ant-form-item-control {
        flex: unset;
    }


    .anlagen {
        width: unset;

    }

    .anlagen>.ant-form-item>.ant-row>.ant-form-item-control {
        flex-direction: unset;

    }

    .toolchild {
        top: 45px;
        right: 20px;
        padding: 8px;
    }

    .textOuter>.ant-form-item>.ant-row>.ant-form-item-label label {
        font-size: 14px;

    }


}

@media (max-width:600px) {

    .fileReader {
        padding-left: 100px;
    }

}

@media (max-width:650px) {

    .txtSection {
        width: 300px;
    }

    .max-content2>.ant-row {
        max-width: 350px;
    }

    .max-content1>.ant-row>.ant-form-item-control {
        align-items: flex-start;
    }

    .sacherCenterText {
        width: unset;
    }

    .sacherCenter1 {
        width: unset;
    }

    .sacherCenter7 {
        width: unset;
    }


    .sacherCenter2 {
        width: unset;
    }


    .sacherCenterText>.ant-form-item>.ant-row>.ant-form-item-control {
        margin-left: 0;
    }


}

@media (max-width:450px) {

    .fileReader {
        padding-left: 300px;
    }

}